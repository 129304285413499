export const data_9 = [
  {
    title: "九月份場次表",
    data: [
      { time: "14:00 - 14:30", show: "光電音造盤絲洞", title: "沉浸式投影" },
      { time: "14:30 - 15:00", show: "杏仁 ミル", title: "MR 互動體驗" },
      { time: "15:00 – 15:30", show: "杏仁 ミル", title: "MR 互動體驗" },
      {
        time: "16:00 – 16:30",
        show: "鶴之舞 + 河馬圓舞曲",
        title: "MR 互動體驗",
      },
      {
        time: "17:00 – 17:30",
        show: "河馬圓舞曲 + 帝江化生",
        title: "MR 互動體驗",
      },
      { time: "18:00 – 18:30", show: "桃符", title: "沉浸式投影" },
      { time: "18:30 - 19:00", show: "光電音造盤絲洞", title: "沉浸式投影" },
    ],
  },
  {
    title: "九月份場次表",
    data: [
      { time: "14:00 - 14:30", show: "光電音造盤絲洞", title: "沉浸式投影" },
      { time: "14:30 - 15:00", show: "杏仁 ミル", title: "MR 互動體驗" },
      { time: "15:00 – 15:30", show: "杏仁 ミル", title: "MR 互動體驗" },
      {
        time: "16:00 – 16:30",
        show: "鶴之舞 + 河馬圓舞曲",
        title: "MR 互動體驗",
      },
      {
        time: "17:00 – 17:30",
        show: "河馬圓舞曲 + 帝江化生",
        title: "MR 互動體驗",
      },
      { time: "18:00 – 18:30", show: "桃符", title: "沉浸式投影" },
      { time: "18:30 - 19:00", show: "光電音造盤絲洞", title: "沉浸式投影" },
    ],
  },
  {
    title: "九月份場次表",
    data: [
      { time: "14:00 - 14:30", show: "光電音造盤絲洞", title: "沉浸式投影" },
      { time: "14:30 - 15:00", show: "杏仁 ミル", title: "MR 互動體驗" },
      { time: "15:00 – 15:30", show: "杏仁 ミル", title: "MR 互動體驗" },
      {
        time: "16:00 – 16:30",
        show: "鶴之舞 + 河馬圓舞曲",
        title: "MR 互動體驗",
      },
      {
        time: "17:00 – 17:30",
        show: "河馬圓舞曲 + 帝江化生",
        title: "MR 互動體驗",
      },
      { time: "18:00 – 18:30", show: "桃符", title: "沉浸式投影" },
      { time: "18:30 - 19:00", show: "光電音造盤絲洞", title: "沉浸式投影" },
    ],
  },
  {
    title: "九月份場次表",
    data: [
      { time: "14:00 - 14:30", show: "光電音造盤絲洞", title: "沉浸式投影" },
      { time: "14:30 - 15:00", show: "杏仁 ミル", title: "MR 互動體驗" },
      { time: "15:00 – 15:30", show: "杏仁 ミル", title: "MR 互動體驗" },
      {
        time: "16:00 – 16:30",
        show: "鶴之舞 + 河馬圓舞曲",
        title: "MR 互動體驗",
      },
      {
        time: "17:00 – 17:30",
        show: "河馬圓舞曲 + 帝江化生",
        title: "MR 互動體驗",
      },
      { time: "18:00 – 18:30", show: "桃符", title: "沉浸式投影" },
      { time: "18:30 - 19:00", show: "光電音造盤絲洞", title: "沉浸式投影" },
    ],
  },
];

export const month_data = [
  { title: "jul_title", index: 4 },
  { title: "aug_title", index: 4 },
];

export const data = [
  {
    id: 5,
    title: "jul_title",
    subtitle: "jul_subtitle",
    data: [
      {
        time: "11:00、12:30、14:00",
        show: "show_name_18",
        title: "guide_category_6",
      },
      {
        time: "11:30、13:00、14:30",
        show: "show_name_19",
        title: "guide_category_6",
      },
      {
        time: "12:00、13:30、15:00",
        show: "show_name_20",
        title: "guide_category_6",
      },
      {
        time: "16:00~18:00",
        show: "show_name_13",
        show2: "show_name_6",
        title: "guide_category_7",
        title2: "guide_category_7",
      },
    ],
  },
  {
    id: 5,
    title: "aug_title",
    subtitle: "aug_subtitle",
    data: [
      {
        time: "11:00、12:30、14:00",
        show: "show_name_18",
        title: "guide_category_6",
      },
      {
        time: "11:30、13:00、14:30",
        show: "show_name_19",
        title: "guide_category_6",
      },
      {
        time: "12:00、13:30、15:00",
        show: "show_name_20",
        title: "guide_category_6",
      },
      {
        time: "16:00~18:00",
        show: "show_name_13",
        show2: "show_name_6",
        title: "guide_category_7",
        title2: "guide_category_7",
      },
    ],
  },
];
